.header {
  background-color: #ff4c4c;
  padding: 10px 20px;
  color: white;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.header-container {
  max-width: 1700px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 18px;
}

.nav {
  display: flex;
  gap: 15px;
}

.nav-item {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  transition: opacity 0.3s;
}

.nav-item:hover {
  opacity: 0.8;
}

.search-container {
  display: flex;
  align-items: center;
  background: white;
  padding: 5px;
  border-radius: 5px;
}

.search-input {
  border: none;
  padding: 5px 10px;
  outline: none;
  font-size: 14px;
}

.search-button {
  background: none;
  border: none;
  color: #e4002b;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
}


/* Адаптивный хедер */
@media screen and (max-width: 1024px) {
  .header-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .logo {
    font-size: 16px;
  }

  .nav {
    gap: 10px;
  }

  .nav-item {
    font-size: 12px;
  }
}

/* Хедер для телефонов */
@media screen and (max-width: 768px) {
  .header {
    padding: 5px 10px;
  }

  .logo {
    font-size: 14px;
  }

  .nav {
    flex-direction: column;
    align-items: center;
  }
}
