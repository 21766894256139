.api-container {
    max-width: 900px;
    margin: 40px 20px;
    font-family: Arial, sans-serif;
  }
  
  .api-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .api-method-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .api-method {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    color: white;
  }
  
  .api-method.post {
    background-color: #007bff;
  }

  .api-method.get {
    background-color: #28a745;
  }
  
  
  .api-endpoint {
    margin-left: 10px;
    font-size: 18px;
    color: #555;
  }
  
  .api-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .api-log-history h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .api-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .api-table th,
  .api-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .api-table th {
    background-color: #f8f9fa;
  }

  .code-block-wrapper {
    margin: 20px 0;
  }
  
  .code-block {
    background: #282c34;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
  }
  
  .response-block {
    margin: 20px 0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .response-block.success {
    background-color: #e6f7e6;
    border: 1px solid #28a745;
  }
  
  .response-block.error {
    background-color: #fdecea;
    border: 1px solid #dc3545;
  }
  
  .api-note {
    background-color: #f1f3f5;
    border-left: 5px solid #007bff;
    padding: 10px;
    margin: 20px 0;
  }
  
  .api-note h3 {
    margin-bottom: 5px;
  }
  