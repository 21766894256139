.request-container {
width: 28%;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.language-selector {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.language-button {
  padding: 6px 12px;
  border: none;
  background: #f0f0f0;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.language-button.active {
  background: #007bff;
  color: #fff;
}

.request-box {
  background: #f8f9fa;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

.request-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
}

.code-box {
  background: #303b42;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

.code-content {
  font-family: monospace;
  font-size: 12px;
}

.request-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.copy-button, .try-button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.copy-button {
  background: #6c757d;
}

.response-box {
  background: #e9ecef;
  border-radius: 5px;
  padding: 15px;
}

.response-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.response-content {
  background: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
}
